import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ViewInfluencers = () => {
  const [influencers, setInfluencers] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchInfluencers = async () => {
      try {
        const response = await axios.get('http://localhost/core/get_influencers.php');
        setInfluencers(response.data);
      } catch (error) {
        console.error(error);
        setMessage('Erro ao carregar influenciadores.');
      }
    };

    fetchInfluencers();
  }, []);

  return (
<div className="influenciadores-container">
    <h2 className="influenciadores-title">Influenciadores</h2>
    {message && <p className="influenciadores-message">{message}</p>}
    <table className="influenciadores-table">
        <thead>
            <tr className="influenciadores-header">
                <th className="influenciadores-header-item">Nome</th>
                <th className="influenciadores-header-item">Canal</th>
                <th className="influenciadores-header-item">Seguidores</th>
                <th className="influenciadores-header-item">Comissão (%)</th>
                <th className="influenciadores-header-item">Status</th>
            </tr>
        </thead>
        <tbody className="influenciadores-body">
            {influencers.map((influencer) => (
                <tr key={influencer.id} className="influenciadores-row">
                    <td className="influenciadores-data">{influencer.nome}</td>
                    <td className="influenciadores-data">{influencer.canal}</td>
                    <td className="influenciadores-data">{influencer.seguidores}</td>
                    <td className="influenciadores-data">{influencer.comissao}</td>
                    <td className="influenciadores-data">{influencer.status}</td>
                </tr>
            ))}
        </tbody>
    </table>
</div>


  );
};

export default ViewInfluencers;
