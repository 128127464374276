import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import qs from 'qs';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/login.php', qs.stringify({ username, password }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      console.log(response.data); // Para depuração
      setMessage(response.data.message);

      if (response.data.message === 'Login bem-sucedido!') {
        sessionStorage.setItem('user_id', response.data.user_id); // Armazena o ID do usuário
        console.log('Navegando para /admin');
        navigate('/admin'); // Redireciona para /admin após login bem-sucedido
      }
    } catch (error) {
      if (error.response) {
        console.error('Erro de autenticação:', error.response.data);
        setMessage(error.response.data.error || 'Erro desconhecido');
      } else {
        console.error('Erro de rede:', error);
        setMessage('Erro de rede, tente novamente.');
      }
    }
  };

  return (
    <div class="dashboard-tp-login-container">
    <div class="dashboard-tp-login-card">
        <div class="dashboard-tp-card-content">
            <h2 class="dashboard-tp-center-align">Login</h2>
            <form onSubmit={handleLogin}>
                <div class="dashboard-tp-input-field">
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        id="username"
                        required
                        class="dashboard-tp-input"
                        placeholder="Usuário"
                    />
                </div>
                <div class="dashboard-tp-input-field">
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        id="password"
                        required
                        class="dashboard-tp-input"
                        placeholder="Senha"
                    />
                </div>
                <button class="dashboard-tp-login-btn" type="submit">
                    Login
                </button>
            </form>
            {message && <p class="dashboard-tp-red-text dashboard-tp-center-align">{message}</p>}
        </div>
    </div>
</div>


  );
};

export default Login;
