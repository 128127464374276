import { useState, useEffect } from 'react';

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null); // null para carregar

  useEffect(() => {
    const userId = sessionStorage.getItem('user_id');
    setIsAuthenticated(!!userId); // true se userId existir, false caso contrário
  }, []);

  return isAuthenticated;
};

export default useAuth;
