import React, {useState} from "react";

function Regras({ aberto, onFechar }) {
    if (!aberto) return null;

 return (
    
    <div>
    
        <div className="menu-principal-fundo">
          <div className="menu-principal-conteudo">
            <div className='linha-menu-bt'>
            <button className="btn-fechar-menu" onClick={onFechar}>
              X
            </button>
            </div>
            
            <div className='linha-menu'>
           Leia atentamente as regras!
            </div>

            <div className='linha-menu'>
            

            <div className="regras-txt-container">
      <p className="regras-txt-paragraph">
        <strong>Termos e Condições do Sorteio TelePix</strong>
      </p>

      <p className="regras-txt-paragraph">
        Estes Termos e Condições estabelecem as regras para a participação nos sorteios realizados pela TelePix. Ao participar de qualquer sorteio promovido pela TelePix, o usuário concorda integralmente com os termos abaixo. <span>Recomendamos a leitura cuidadosa de todas as cláusulas.</span>
      </p>

      <p className="regras-txt-titulo">1. Objeto do Sorteio</p>
      <p className="regras-txt-paragraph"><span>1.1.</span> A TelePix realiza sorteios para premiar os participantes que adquirirem a apostila do tema vigente. <span>1.2.</span> Ao adquirir a apostila, o participante automaticamente recebe números gratuitos para concorrer a prêmios em formato de transferência via Pix. <span>1.3.</span> O sorteio ocorre de forma ao vivo e é registrado por meio de transmissão pública, promovendo a transparência e a credibilidade do processo.</p>

      <p className="regras-txt-titulo">2. Aquisição e Validação do Pagamento</p>
      <p className="regras-txt-paragraph"><span>2.1.</span> A participação no sorteio é vinculada à aquisição da apostila do tema atual. <span>2.2.</span> Para fins de segurança e validade, a transação de aquisição da apostila será checada pela equipe da TelePix. <span>2.3.</span> Caso algum dado fornecido pelo participante se apresente inconsistente ou inválido, a TelePix poderá solicitar o comprovante de pagamento ou outros documentos adicionais. <span>2.4.</span> A liberação do prêmio ao ganhador só ocorrerá após a comprovação da aquisição válida da apostila e dos dados corretos na plataforma da TelePix.</p>

      <p className="regras-txt-titulo">3. Regras do Sorteio e Apuração do Ganhador</p>
      <p className="regras-txt-paragraph"><span>3.1.</span> O sorteio será realizado até que um número válido corresponda a um ganhador, garantindo que o prêmio não acumule. <span>3.2.</span> O prazo para a realização do sorteio é de 7 (sete) dias após o início da campanha vigente. Caso não haja um ganhador, o sorteio prosseguirá ao vivo até que um vencedor seja determinado. <span>3.3.</span> O ganhador será determinado pela sequência de números sorteada, de forma aleatória, pela plataforma utilizada pela TelePix. <span>3.4.</span> A TelePix reserva-se o direito de realizar sorteios adicionais e premiar mais de um participante, a seu exclusivo critério, desde que as condições estabelecidas nestes Termos sejam respeitadas.</p>

      <p className="regras-txt-titulo">4. Prêmio e Transferência</p>
      <p className="regras-txt-paragraph"><span>4.1.</span> O prêmio será pago ao ganhador no formato de transferência via Pix, no valor estabelecido no regulamento específico do sorteio. <span>4.2.</span> A TelePix não se responsabiliza pela precisão dos dados de Pix fornecidos pelo participante e não realizará transferências para contas bancárias que não estejam no nome do ganhador ou fornecidas de maneira inconsistente. <span>4.3.</span> Em caso de dúvida sobre o recebimento do prêmio, o participante deverá entrar em contato com a TelePix no prazo de 10 (dez) dias após a realização do sorteio.</p>

      <p className="regras-txt-titulo">5. Proibição de Participação por Membros da TelePix</p>
      <p className="regras-txt-paragraph"><span>5.1.</span> Funcionários, representantes, parceiros e prestadores de serviço da TelePix estão expressamente proibidos de participar dos concursos e sorteios. <span>5.2.</span> A participação destes indivíduos em sorteios, mesmo de maneira informal, será considerada fraude e resultará na anulação de qualquer premiação.</p>

      <p className="regras-txt-titulo">6. Autorização para Uso de Imagem</p>
      <p className="regras-txt-paragraph"><span>6.1.</span> Ao participar do sorteio, o usuário concede à TelePix o direito de uso gratuito de sua imagem para fins de registro de reação no momento do sorteio e recebimento do prêmio. <span>6.2.</span> A imagem poderá ser usada em mídias digitais, impressas e redes sociais da TelePix, exclusivamente para divulgação dos resultados e fortalecimento da credibilidade do sorteio. <span>6.3.</span> O participante cede todos os direitos de uso de imagem à TelePix, sem que haja necessidade de qualquer compensação adicional.</p>

      <p className="regras-txt-titulo">7. Disposições Gerais</p>
      <p className="regras-txt-paragraph"><span>7.1.</span> O participante declara que concorda integralmente com estes Termos ao prosseguir com a aquisição da apostila e participar do sorteio. <span>7.2.</span> A TelePix reserva-se o direito de alterar qualquer cláusula deste Termo, desde que comunique previamente os participantes. <span>7.3.</span> Este Termo é regido pelas leis vigentes no país. Em caso de litígio, as partes elegem o foro da Comarca de [São Paulo/SP] como competente para resolver eventuais controvérsias decorrentes deste Termo.</p>

      <p className="regras-txt-paragraph">Estes Termos de Participação são válidos a partir da data de sua publicação e aplicam-se a todos os sorteios realizados pela TelePix no âmbito da campanha vigente.</p>
    </div>

            </div>
           
            
          </div>
        </div>


</div>
        
      
)
};

export default Regras;