import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const TabelaConcursos = () => {
  const [concursos, setConcursos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalData, setModalData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const fetchConcursos = async (page = 1) => {
    setLoading(true);
    try {
      const response = await axios.post('/listar_concursos.php', { page });
      setConcursos(response.data.concursos || []);
    } catch (error) {
      console.error('Erro ao listar concursos:', error);
      setConcursos([]);
    } finally {
      setLoading(false);
    }
  };

  const openModal = (concurso) => {
    setModalData(concurso);
    setIsModalOpen(true);  // Exibe o modal
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalData(null);
  };

  const handleTransferAndDelete = async () => {
    if (!modalData) return;

    if (window.confirm('Tem certeza que deseja transferir todos os bilhetes premiados e deletá-los?')) {
        try {
            const response = await axios.post('/ganhadores.php', {
                concurso_id: modalData.id // Você pode passar o ID do concurso se precisar
            });

            if (response.data.success) {
                alert(response.data.message);
                fetchConcursos(); // Atualiza a lista de concursos
            } else {
                alert(`Erro ao transferir e deletar bilhetes: ${response.data.message}`);
            }
        } catch (error) {
            console.error('Erro na requisição:', error);
            alert('Erro ao transferir e deletar bilhetes.');
        }
    }
};


  const handleGenerateBilhetes = async () => {
    if (!modalData) return;

    try {
      const concursoResponse = await axios.post('/obter_concurso.php', {
        concurso_id: modalData.id,
      });

      const { success, bilhetes_disponiveis, bilhetes_extras, premio_extra } = concursoResponse.data;

      if (!success) {
        alert(concursoResponse.data.message || 'Erro ao obter dados do concurso.');
        return;
      }

      const bilhetesSemValor = bilhetes_disponiveis - bilhetes_extras;
      const maxPremio = parseFloat(premio_extra);

      let bilhetesComValor = [];
      if (bilhetes_extras >= 2) {
        bilhetesComValor.push(maxPremio * 0.20);
        bilhetesComValor.push(maxPremio * 0.10);
      }

      let restantePremio = maxPremio - bilhetesComValor.reduce((acc, val) => acc + val, 0);

      for (let i = 2; i < bilhetes_extras; i++) {
        let valorAleatorio = Math.random() * (restantePremio / (bilhetes_extras - i));
        if (valorAleatorio < 10) valorAleatorio = 10.00;
        bilhetesComValor.push(valorAleatorio);
        restantePremio -= valorAleatorio;
      }

      let bilhetes = [...bilhetesComValor, ...Array(bilhetesSemValor).fill(0.00)];

      const response = await axios.post('/gerar_bilhetes.php', {
        concurso_id: modalData.id,
        bilhetes,
        premio_extra: maxPremio
      });

      if (response.data.success) {
        alert('Bilhetes gerados com sucesso!');
        fetchConcursos();
      } else {
        alert(`Erro ao gerar bilhetes: ${response.data.message}`);
      }
    } catch (error) {
      console.error('Erro na requisição:', error);
      alert('Erro ao gerar bilhetes.');
    }
  };

  const handleDeleteBilhetes = async () => {
    if (!modalData) return;

    if (window.confirm('Tem certeza que deseja deletar todos os bilhetes deste concurso?')) {
      try {
        const response = await axios.post('/deletar_bilhetes.php', {
          concurso_id: modalData.id
        });

        if (response && response.data) {
          if (response.data.success) {
            alert(response.data.message);
            fetchConcursos();
          } else {
            alert(`Erro ao deletar bilhetes: ${response.data.message}`);
          }
        } else {
          alert('Resposta inesperada do servidor.');
        }
      } catch (error) {
        console.error('Erro na requisição:', error);
        alert('Erro ao deletar bilhetes.');
      }
    }
  };

  const deleteConcurso = async (id) => {
    if (window.confirm('Tem certeza que deseja deletar este concurso?')) {
      try {
        await axios.post('/deletar_concurso.php', { id });
        fetchConcursos();
      } catch (error) {
        console.error('Erro ao deletar concurso:', error);
      }
    }
  };

  const toggleStatus = async (id, currentStatus) => {
    try {
      await axios.post('/atualizar_status.php', { id, status: !currentStatus });
      fetchConcursos();
    } catch (error) {
      console.error('Erro ao atualizar status do concurso:', error);
    }
  };

  const handlePageChange = (newPage) => {
    navigate(`/admin/ver-concurso/${newPage}`);
    fetchConcursos(newPage);
  };

  useEffect(() => {
    const page = window.location.pathname.split('/').pop();
    const pageNumber = isNaN(page) ? 1 : parseInt(page);
    fetchConcursos(pageNumber);
  }, []);

  return (
    <div className="tabela-container">
    <h2>Concursos</h2>
    {loading ? (
      <div>Carregando...</div>
    ) : (
      <div className="flip-card-container">
        <table className="tabela-verconcurso">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nome do Concurso</th>
              <th>Prêmio Principal</th>
              <th>Status</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(concursos) && concursos.length > 0 ? (
              concursos.map((concurso) => (
                <tr key={concurso.id}>
                  <td>{concurso.id}</td>
                  <td>{concurso.nome}</td>
                  <td>{concurso.premio_principal}</td>
                  <td>
                    <label className="switch-verconcurso">
                      <input
                        type="checkbox"
                        checked={concurso.status === 'ativo'}
                        onChange={() => toggleStatus(concurso.id, concurso.status === 'ativo')}
                      />
                      <span className="slider-verconcurso round"></span>
                    </label>
                  </td>
                  <td>
                    <button className="btn-verconcurso btn-info-verconcurso" onClick={() => openModal(concurso)}>
                      Ver Detalhes
                    </button>
                    <button className="btn-verconcurso btn-danger-verconcurso" onClick={() => deleteConcurso(concurso.id)}>
                      Deletar
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">Nenhum concurso encontrado.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    )}
  
    {/* Modal */}
    {isModalOpen && modalData && (
      <div className="modal-verconcurso" onClick={closeModal}>
        <div className="modal-content-verconcurso" onClick={(e) => e.stopPropagation()}>
          <div className="modal-header-verconcurso">
            <h5 className="modal-title-verconcurso">{modalData.nome}</h5>
            <span className="modal-close-button-verconcurso" onClick={closeModal}>&times;</span>
          </div>
          <div className="modal-body-verconcurso">
            <p>Prêmio Principal: {modalData.premio_principal}</p>
            <p>Prêmio Extra: {modalData.premio_extra}</p>
            <p>Bilhetes Disponíveis: {modalData.bilhetes_disponiveis}</p>
            <p>Bilhetes Extras: {modalData.bilhetes_extras}</p>
          </div>
          <div className="modal-footer-verconcurso">
            <button className="btn-verconcurso btn-success-verconcurso" onClick={handleGenerateBilhetes}>
              Gerar Bilhetes
            </button>
            <button className="btn-verconcurso btn-danger-verconcurso" onClick={handleDeleteBilhetes}>
              Deletar Bilhetes
            </button>
            <button className="btn-verconcurso btn-warning-verconcurso" onClick={handleTransferAndDelete}>
              Transferir e Deletar Bilhetes
            </button>
            <button className="btn-verconcurso btn-secondary-verconcurso" onClick={closeModal}>
              Fechar
            </button>
          </div>
        </div>
      </div>
    )}
  </div>
  
);

};

export default TabelaConcursos;
