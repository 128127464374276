import 'core-js/stable';
import { useState } from 'react';
import { useOpenPix } from '@openpix/react';
import { QRCodeSVG } from 'qrcode.react';

const Pagamento = () => {
  const [charge, setCharge] = useState(null);
  const [error, setError] = useState(null);

  const onPay = (charge) => {
    console.log('charge was paid:', charge);
  }

  const { chargeCreate } = useOpenPix({
    appID: process.env.REACT_APP_OPEN_PIX_APP_ID, // Certifique-se de que a variável de ambiente está correta
    onPay,
  });

  const newCharge = async () => {
    const payload = {
      correlationID: '0520sa02',
      value: 1000, // R$ 10,00
      comment: 'Donate',
    }

    const { charge, error } = await chargeCreate(payload);

    if (error) {
      setError(error);
      return;
    }

    setCharge(charge);
  }

  const copyToClipboard = () => {
    if (charge) {
      navigator.clipboard.writeText(charge.paymentLinkUrl)
        .then(() => alert('Link copiado para a área de transferência!'))
        .catch(err => console.error('Erro ao copiar: ', err));
    }
  };

  if (charge) {
    console.log('Charge criada:', charge); // Verifique a estrutura do objeto charge aqui
    return (
      <div>
        <QRCodeSVG
          size={200}
          value={charge.brCode || 'N/A'} // Corrigido para charge.brCode
          includeMargin={false}
        />
        <p>Link para pagamento: <a href={charge.paymentLinkUrl} target="_blank" rel="noopener noreferrer">{charge.paymentLinkUrl}</a></p>
        <button onClick={copyToClipboard}>
          Copiar Link
        </button>
      </div>
    );
  }

  if (error) {
    return <p>Erro: {error.message}</p>;
  }

  return (
    <>
      <button onClick={newCharge}>
        Create New Charge
      </button>
    </>
  )
}

export default Pagamento;
