import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import { FaHome, FaClipboardList, FaUsers, FaUserPlus, FaCog, FaSignOutAlt } from 'react-icons/fa';

const Dashboard = () => {
  function toggleMenu() {
    const sidebar = document.querySelector('.dashboard-tp-sidebar');
    sidebar.classList.toggle('active'); // Add or remove the 'active' class
  }

  return (
    <div className="dashboard-tp-container">
      <div className="menu-hamburguer" onClick={toggleMenu} role="button" aria-label="Toggle menu">&#9776;</div>
      
      <div className="dashboard-tp-sidebar">
        <div className="dashboard-tp-sidebar-header">
          <h5>SisPix</h5>
        </div>
        
        <nav className="dashboard-tp-sidebar-menu">
          <Link to="/admin" className="dashboard-tp-sidebar-item" aria-label="Home">
            <FaHome className="dashboard-tp-icon" /> Home
          </Link>
          <Link to="/admin/abrir-concurso" className="dashboard-tp-sidebar-item" aria-label="Abrir Concurso">
            <FaClipboardList className="dashboard-tp-icon" /> Abrir Concurso
          </Link>
          <Link to="/admin/ver-concurso" className="dashboard-tp-sidebar-item" aria-label="Ver Concurso">
            <FaClipboardList className="dashboard-tp-icon" /> Ver Concurso
          </Link>
          <Link to="/admin/influencers" className="dashboard-tp-sidebar-item" aria-label="Visualizar Influenciadores">
            <FaUsers className="dashboard-tp-icon" /> Visualizar Influenciadores
          </Link>
          <Link to="/admin/add-influencers" className="dashboard-tp-sidebar-item" aria-label="Cadastrar Influenciador">
            <FaUserPlus className="dashboard-tp-icon" /> Cadastrar Influenciador
          </Link>
          <Link to="/admin/configuracoes" className="dashboard-tp-sidebar-item" aria-label="Configurações">
            <FaCog className="dashboard-tp-icon" /> Configurações
          </Link>
          <Link to="/logout" className="dashboard-tp-sidebar-item" aria-label="Sair">
            <FaSignOutAlt className="dashboard-tp-icon" /> Sair
          </Link>
        </nav>
      </div>

      <div className="dashboard-tp-content">
        <nav className="dashboard-tp-navbar">
          <h1>Painel de Controle</h1>
        </nav>
        <div className="dashboard-tp-content-body">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
