import React, {useState} from "react";

function Sorteios({ aberto, onFechar }) {
    if (!aberto) return null;

 return (
    
    <div>
    
        <div className="menu-principal-fundo">
          <div className="menu-principal-conteudo">
            <div className='linha-menu-bt'>
            <button className="btn-fechar-menu" onClick={onFechar}>
              X
            </button>
            </div>
            
            <div className='linha-menu'>
           Sorteios
            </div>

            <div className='linha-menu'>
            Datas de Sorteios

            </div>
           
            
          </div>
        </div>


</div>
        
      
)
};

export default Sorteios;