import React, { useEffect, useState } from 'react';
import 'core-js/stable';
import { QRCodeSVG } from 'qrcode.react';
import BilhetesPre from './componentes/BilhetesPre'
import { ImMenu } from "react-icons/im";
import { IoTicketOutline } from "react-icons/io5";
import Ganhadores from './componentes/Ganhadores';
import Sorteios from './componentes/Sorteios';
import Regras from './componentes/Regras';
import Contato from './componentes/Contato';


const ConcursoPage = () => {
  const [concurso, setConcurso] = useState(null);
  const [bilhetes, setBilhetes] = useState([]);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [modalOpen_cpf_consulta, setModalOpen_cpf_consulta] = useState(false);
  const [cpf_cpf_consulta, setCpf_cpf_consulta] = useState('');
  const [dadosBilhetes_cpf_consulta, setDadosBilhetes_cpf_consulta] = useState(null);
  const [error_cpf_consulta, setError_cpf_consulta] = useState(null);
  const [loading_cpf_consulta, setLoading_cpf_consulta] = useState(false);
  const [dataSecundaria, setDataSecundaria] = useState('');
  const [modalType, setModalType] = useState(null);
  const [quantidade, setQuantidade] = useState(1);
  const [total, setTotal] = useState(0);
  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');
  const [telefone, setTelefone] = useState('');
  const [charge, setCharge] = useState(null);
  const [error, setError] = useState(null);
  const [paymentReceived, setPaymentReceived] = useState(false);
  const [counter, setCounter] = useState(30); 
  const [errorEfi, setErrorEfi] = useState(null); 
  const [modalOpened, setModalOpened] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [closingTimer, setClosingTimer] = useState(null); 
  const [menuAberto, setMenuAberto] = useState(false);
  const [ganhadoresAberto, setGanhadoresAberto] = useState(false);
  const [sorteioAberto, setsorteioAberto] = useState(false);
  const [regrasAberto, setRegrasAberto] = useState(false);
  const [contatosAberto, setContatosAberto] = useState(false);


  const abrirMenu = () => {
    setMenuAberto(true);
  }
  
  const fecharMenu = () => {
    setMenuAberto(false);
  }


  const abrirSorteio = () => {
    setsorteioAberto(true);
    fecharMenu();
  };

  const fecharSorteio = () => {
    setsorteioAberto(false);
    abrirMenu();
  };

  const abrirGanhadores = () => {
    setGanhadoresAberto(true);
    fecharMenu();
  };

  const fecharGanhadores = () => {
    setGanhadoresAberto(false);
    abrirMenu();
  };

  const abrirRegras = () => {
    setRegrasAberto(true);
    fecharMenu();
  };

  const fecharRegras = () => {
    setRegrasAberto(false);
    abrirMenu();
  };

  const abrirContatos = () => {
    setContatosAberto(true);
    fecharMenu();
  };

  const fecharContatos = () => {
    setContatosAberto(false);
    abrirMenu();
  };



  useEffect(() => {
    if (paymentReceived) { // Quando o pagamento é confirmado
        const timer = setTimeout(() => {
            closeModal(); // Fecha o modal após 5 segundos, por exemplo
        }, 5000); // Tempo de espera (5 segundos)

        setClosingTimer(timer); // Armazena o ID do temporizador para referência futura
    }

    // Limpa o temporizador quando o componente desmonta ou o pagamentoReceived muda
    return () => {
        if (closingTimer) {
            clearTimeout(closingTimer); // Limpa o temporizador
        }
    };
}, [paymentReceived, closingTimer]);




  const onPay = (charge) => {
    console.log('Pagamento recebido:', charge);
    setPaymentReceived(true);
    handleCadastrarBilhete(charge); // Chama a função para cadastrar o bilhete
  };

  // Função para abrir o modal
  const abrirModal_cpf_consulta = () => {
    setModalOpen_cpf_consulta(true);
  };

  // Função para fechar o modal
  const fecharModal_cpf_consulta = () => {
    setModalOpen_cpf_consulta(false);
    setCpf_cpf_consulta('');
    setDadosBilhetes_cpf_consulta(null);
    setError_cpf_consulta(null);
  };

  // Função para buscar os bilhetes pelo CPF
  const buscarBilhetes_cpf_consulta = async () => {
    setLoading_cpf_consulta(true);
    setError_cpf_consulta(null);

    try {
      const response = await fetch(`/meusbilhetes.php?cpf=${cpf_cpf_consulta}`); // URL para o seu backend
      if (!response.ok) throw new Error('Erro ao buscar bilhetes');

      const data = await response.json();

      if (data.success) {
        setDadosBilhetes_cpf_consulta(data);
      } else {
        throw new Error(data.message || 'Nenhum bilhete encontrado para o CPF fornecido');
      }
    } catch (error) {
      setError_cpf_consulta(error.message);
    } finally {
      setLoading_cpf_consulta(false);
    }
  };

   // Função de submit ao clicar no botão "Buscar"
   const handleSubmit_cpf_consulta = (e) => {
    e.preventDefault();
    if (cpf_cpf_consulta.trim() === '') {
      setError_cpf_consulta('Por favor, insira um CPF válido');
      return;
    }
    buscarBilhetes_cpf_consulta();
  };

// Função para formatar o CPF sem alterar o valor no estado
const formatarCPF = (value) => {
  // Armazena somente os números no estado
  const apenasNumeros = value.replace(/\D/g, '');
  setCpf(apenasNumeros);
  
  // Formatação para visualização (ex.: 000.000.000-00)
  return apenasNumeros.replace(/(\d{3})(\d)/, '$1.$2')
                       .replace(/(\d{3})(\d)/, '$1.$2')
                       .replace(/(\d{3})(\d{1,2})$/, '$1-$2');
};


// Função para formatar o telefone sem alterar o valor no estado
const formatarTelefone = (value) => {
  const apenasNumeros = value.replace(/\D/g, '');
  setTelefone(apenasNumeros); // Armazena somente os números no estado
  
  // Formatação para visualização (ex.: (00) 00000-0000)
  if (apenasNumeros.length <= 11) {
      return apenasNumeros.replace(/(\d{2})(\d)/, '($1) $2')
                          .replace(/(\d{5})(\d)/, '$1-$2');
  }
  return value;
};



  // Após o pagamento confirmado, envia os dados ao backend para cadastrar o bilhete
  const handleCadastrarBilhete = async (charge) => {
    const bilhetes = [];
    for (let i = 0; i < quantidade; i++) {
      const bilheteData = {
        nome: nome,
        cpf: cpf,
        telefone: telefone,
        total_vendas: total.toFixed(2), // Total calculado
        id_concurso: concurso.id,
        transacao: charge.endToEndId, // Atualizado para endToEndId
        valor_por_bilhete: concurso.custo_bilhete // Adicione esta linha
      };
      bilhetes.push(bilheteData);
    }

    console.log('Dados enviados para o servidor:', bilhetes);

    try {
      const response = await fetch('/vendas.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ bilhetes }),
      });
    
      if (response.ok) {
        const data = await response.json();
        console.log('Bilhetes cadastrados com sucesso:', data);
      } else {
        throw new Error('Erro ao cadastrar bilhetes');
      }
    } catch (error) {
      console.error('Erro ao cadastrar bilhetes:', error.message);
    }
    
  };

  useEffect(() => { 
    const fetchBilhetes = async () => {
      try {
        const response = await fetch('/bilhetes_premiados.php'); // Substitua pela sua URL de API
        if (!response.ok) throw new Error('Erro ao carregar bilhetes');
        const data = await response.json();

        console.log(data); // Verifique o formato dos dados aqui

        if (data.success) {
          setBilhetes(data.bilhetes);
        } else {
          throw new Error(data.message || 'Erro ao carregar os bilhetes');
        }
      } catch (error) {
        setError(error.message);
      }
    };

    fetchBilhetes(); // Chamada inicial
}, []); // O array vazio garante que a chamada seja feita apenas na montagem


const newCharge = async () => {
  const cpfSemMascara = cpf.replace(/\D/g, '');
  const telefoneSemMascara = telefone.replace(/\D/g, '');

  const payload = {
    nome: nome,
    cpf: cpfSemMascara,
    telefone: telefoneSemMascara,
    valor_total: total,
  };

  try {
    const response = await fetch('/efi.php', { // Altere para sua URL
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    const data = await response.json();

    // Verifica se houve erro na resposta
    if (data.error) {
      console.log('Erro detectado:', data.error);
      if (data.error.includes('Documento CPF em devedor.cpf é inválido')) {
        setErrorEfi('CPF inválido, verifique a digitação.');
      } else {
        setErrorEfi(data.error);
      }
      console.log('Modal não será aberto devido ao erro.');
      return; // Não continua se houver erro
    }

    // Verifica se a cobrança foi gerada corretamente antes de abrir o modal
    if (data && data.brCode) {
      setCharge(data); // Atualiza o estado com os dados válidos da cobrança
      setModalType('qrCode'); // Abre o modal de QR code somente após a cobrança ser gerada
      console.log('Cobrança gerada com sucesso, abrindo modal qrCode');
    } else {
      setErrorEfi('Erro ao gerar cobrança. Tente novamente.');
      console.log('Erro: Cobrança não gerada.');
    }

  } catch (error) {
    // Lida com erros da requisição
    console.error('Erro na requisição:', error);
    setErrorEfi(error.message || 'Ocorreu um erro inesperado.');
  }
};

// Certifique-se de que não há chamadas para openModal fora de newCharge



    
  
  const ModalPagamentoPix = ({ charge, closeModal }) => {
    const [paymentReceived, setPaymentReceived] = useState(false);
    const [closingTimer, setClosingTimer] = useState(null);
    const [bilheteCadastrado, setBilheteCadastrado] = useState(false); // Flag para controle de cadastro

    const copyToClipboard = () => {
      if (charge && charge.pixCopiaECola) {
        navigator.clipboard.writeText(charge.pixCopiaECola)
          .then(() => {
            alert('Texto copiado para a área de transferência!');
          })
          .catch((err) => {
            console.error('Erro ao copiar texto: ', err);
          });
      } else {
        alert('Nenhum código Copia e Cola disponível.');
      }
    };

    useEffect(() => { 
      const intervalId = setInterval(() => {
        const confirmarPagamento = async () => {
          if (charge && charge.txid) {
            try {
              const response = await fetch(`https://autoprotech.com.br/webhook/consulta.php?txid=${charge.txid}`, {
                method: 'GET',
              });
              const data = await response.json();
    
              if (data && data.length > 0 && !bilheteCadastrado) {
                const paymentData = data[0];
                await handleCadastrarBilhete(paymentData); // Cadastra o bilhete com os dados do pagamento
                setBilheteCadastrado(true);
                setPaymentReceived(true);
                clearInterval(intervalId);
    
                // Inicia um temporizador para fechar o modal após 10 segundos
                const timerId = setTimeout(() => {
                  closeModal();
                  window.location.href = '/'; // Redireciona para a página inicial
                }, 10000);
                setClosingTimer(timerId);
              }
            } catch (error) {
              console.error('Erro ao confirmar pagamento:', error);
            }
          }
        };
        confirmarPagamento();
      }, 5000);
    
      return () => {
        clearInterval(intervalId);
        clearTimeout(closingTimer);
      };
    }, [charge, closingTimer, bilheteCadastrado]);
    
    return (
      <div>
        {paymentReceived ? (
          <>
            <h2 style={{ textAlign: 'center', padding: '20px' }}>Pagamento Confirmado! Obrigado!</h2>
          </>
        ) : (
          <>
            <div className='aguardando'>Aguardando Confirmação...</div>
            {charge ? (
              <>
                <div className='aguardando-valor-txt'>Valor</div>
                <div className='aguadando-valor'>R$ {charge.valor}</div>
                <div className='linha-qr'>
                  <div className='qr-bg'>
                    <QRCodeSVG size={200} value={charge.brCode} includeMargin={false} />
                  </div>
                </div>
                <div className='aguadando-link-txt'>Link para pagamento:</div>
                <div className='linha-qr'>
                  <div className='aguardando-link'><a href={`https://${charge.location}`} target="_blank" rel="noopener noreferrer">{charge.location}</a></div>
                </div>
                <div className='aguardando-copia-txt'>Copia e Cola:</div>
                <textarea className='area-copia-cola' readOnly value={charge.pixCopiaECola} />
                <div className='linha-qr'>
                  <button className='aguardando-bt-copia-cola' onClick={copyToClipboard}>Copia e Cola Pix</button>
                  <button onClick={() => window.location.reload()} className="aguardando-bt-cencelar">CANCELAR</button>
                </div>
              </>
            ) : (
              <p>Gerando cobrança...</p>
            )}
          </>
        )}
    
        {paymentReceived && (
          <div className='pag-ok' id="charge-identifier">
            <strong>Pagamento Recebido!</strong>
          </div>
        )}
      </div>
    );
  };




  useEffect(() => {
    const fetchConcurso = async () => {
      setLoading(true); // Inicia o carregamento
      try {
        const response = await fetch('/obter_concurso_ativo.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const data = await response.json();

        if (data.success) {
          setConcurso(data.concurso);
          setBilhetes(data.bilhetes);

          // Adicionando 7 dias à data de criação do concurso
          const dataCriacao = new Date(data.concurso.data_criacao);
          const dataSecundariaTemp = new Date(dataCriacao);
          dataSecundariaTemp.setDate(dataCriacao.getDate() + 7); // Adiciona 7 dias

          // Armazena a nova data no estado
          setDataSecundaria(dataSecundariaTemp.toLocaleDateString());

          // Aqui você pode acessar o ID do concurso
          const concursoId = data.concurso.id;
          console.log('ID do Concurso:', concursoId);
        } else {
          setMessage(data.message);
        }
      } catch (error) {
        console.error('Erro ao buscar concurso:', error);
        setMessage('Erro ao buscar concurso.');
      } finally {
        setLoading(false); // Finaliza o carregamento
      }
    };

    fetchConcurso();
  }, []);

  useEffect(() => {
    if (concurso) {
      setTotal(concurso.custo_bilhete * quantidade);
    }
  }, [quantidade, concurso]);

  const openModal = (type) => {
    setModalType(type);
  };

  const closeModal = () => {
    setModalType(null);
    resetForm();
    setPaymentReceived(false); // Reseta o estado de pagamento ao fechar o modal
    clearTimeout(closingTimer); // Limpa o temporizador para evitar fechamentos duplicados
  };


  const resetForm = () => {
    setNome('');
    setCpf('');
    setTelefone('');
    setQuantidade(1);
  };



  const handleComprar = () => {
    openModal('compras');
  };

  const handleConcluirCompra = async () => {
    // Verifica se todos os campos obrigatórios estão preenchidos
    if (!nome || !cpf || !telefone) {
      setErrorMessage("Por favor, preencha todos os campos obrigatórios.");
      return;
    }
  
    // Limpa a mensagem de erro, caso exista
    setErrorMessage("");
  
    // Gera a cobrança
    await newCharge();
  
    // Após gerar a cobrança, fecha o modal de compra e abre o modal de QR code
    setModalType('qrCode');
  };
  
  
  

  useEffect(() => {
    console.log('Modal Type:', modalType);
  }, [modalType]);


  const handleQuantidadeChange = (increment) => {
    if (increment === 'increment') {
      setQuantidade(quantidade + 1);
    } else {
      setQuantidade(increment);
    }
  };

  const handleQuantidadeRemove = (decrement) => {
    if (decrement === 'decrement') {
      if (quantidade > 0) {
        setQuantidade(quantidade - 1);
      }
    } else {
      setQuantidade(decrement);
    }
  };
  

  if (loading) {
    return <div>Carregando...</div>;
  }

  return (
    <div className='container'>
       {concurso ? (
         <div>

           <nav>
            <div className='logo'>
              <img className='logotipo-telepix' src='/img/logo_telepix.png' alt={concurso.nome} />
            </div>
            <div className='menu-hamburguer' onClick={abrirMenu}><ImMenu className='hamb-menu' /></div>
           </nav>

           <header>
            <div className='c50'>

              <div className="circle-container">
                <div className="orbit orbit-outer">
                  <div className="ball"></div>
                  <div className="ball"></div>
                  <div className="ball"></div>
                </div>
                <div className="orbit orbit-inner">
                  
                </div>
                <div className="center-image">
                <img className='img-orb' src={`${concurso.imagem}?t=${Date.now()}`} alt={concurso.nome} />
                </div>
              </div>

            </div>
            <div className='c50'>
                <div className='borda-efeito'>
              <div className='card-info'>

                <div className='linha'>
                  <div className='separador-trac'></div>
                  <div className='linha'>
                  <p className='txt-branco'>Iniciado em: {new Date(concurso.data_criacao).toLocaleDateString()}</p>
                  </div>
                  <div className='separador-trac'></div>
                </div>
              
              <div className='label-sorteio'>
              <div className='label-circ'></div>
              <div className='linha'>
                  <p className='txt-branco'> <strong>Próximo</strong> Sorteio</p>
                  </div>
              <div className='label-square'></div>
              </div>

              <div className='label-premio-valor'>
              <div className='txt-valor-premio'>
   {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(concurso.premio_principal)}
              </div>

              <div className='label-square-preto'></div>
              </div>

                <div className='linha'>
              <div className='label-premio-total'>
               <div className=''><p>Prêmio principal</p></div>
                <div className=''></div>

              </div>
              </div>

              <div className='linha mt20'>
                <div className='c50 mr10'>
                  <p className='txt-branco-100'>Sorteio ao Vivo</p>
                  <div className='separador-trac mb5 mt5'></div>
                  <p className='txt-branco-100'>@telepixoficial</p>
                </div>
                <div className='c50 mr10'>
                <p className='txt-branco-100'>{dataSecundaria}</p>
                <div className='separador-trac mb5 mt5'></div>
                <p className='txt-branco-100'>20h00</p>
                </div>
              </div>
              
              </div>
              

            </div>
            </div>
           </header>

           <section className='fundo-preto'>
           <div className='se-comprar'>
            <div className='c33'>
              <p className='txt-comprar-l'>Transforme conhecimento em sorte, garanta seu bilhete!</p>
            </div>
            <div className='c33'>
                <div className='comprar-bilhete-borda'>
                <div className='comprar-bilhete'>
                  <p className='apostilas-txt'>apostilas +</p>
                  <p className='bilhetes-txt'>Bilhetes</p>
                  <p className='gratis-txt'>grátis</p>
                  <div className='visor-comprar-bilhetes'>
                    <p>R$ {total.toFixed(2)}</p>
                  </div>
                  <div className='multiplicador'>
                      <button className='xn' type="button" onClick={() => handleQuantidadeChange(1)}>x1</button>
                      <button className='xn' type="button" onClick={() => handleQuantidadeChange(3)}>x3</button>
                      <button className='xn' type="button" onClick={() => handleQuantidadeChange(5)}>x5</button>
                      <button className='xn' type="button" onClick={() => handleQuantidadeChange(10)}>x10</button>
                      <button className='xmais' type="button" onClick={() => handleQuantidadeChange('increment')}>+</button>
                      <button className='xmenos' type="button" onClick={() => handleQuantidadeRemove('decrement')}>-</button>
                      <div className='lateral-label'>
                    <img className='' src='/img/label_lateral.png' alt={concurso.nome} />
                    </div>
                  <button className='btn-comprar' type="button" onClick={handleComprar}> Comprar </button>
                  </div>
                  
                </div>
                </div>
            </div>
            <div className='c33'>
              <p className='txt-comprar-2'>Amplie suas chances de sucesso! Ao adquirir nossas apostilas digitais, você não só se prepara para dominar o mercado, como também recebe números da sorte exclusivos.</p>
            </div>
            </div>

           </section>

           <section className='se-bilhetes-premiados'>
            <div className='txt-bp-l1'> Bilhetes e Prêmios </div>
            <div className='txt-bp-l2'> Encontrou, ganhou! Prêmios instantaneos para você. </div>
      
            <BilhetesPre />
           
           </section>

   

          
            {/* Modal de Compra */}
{modalType === 'compras' && (
  <div className="modal-comprar modal-open">
    <div className="modal-content-comprar">
      <span className="close-comprar" onClick={closeModal}>&times;</span>
      <div className='modal-comprar-l1'>Comprar Bilhetes</div>
      <div className='modal-comprar-l2'>Valor Total:</div>
      <div className='modal-comprar-l3'>R$ {total.toFixed(2)}</div>
      <div className='linha-modal-btn'>
        {/* Botões de quantidade */}
        <button className='xn' type="button" onClick={() => handleQuantidadeChange(1)}>x1</button>
        <button className='xn' type="button" onClick={() => handleQuantidadeChange(3)}>x3</button>
        <button className='xn' type="button" onClick={() => handleQuantidadeChange(5)}>x5</button>
        <button className='xn' type="button" onClick={() => handleQuantidadeChange(10)}>x10</button>
        <button className='xmais' type="button" onClick={() => handleQuantidadeChange('increment')}>+</button>
        <button className='xmenos' type="button" onClick={() => handleQuantidadeRemove('decrement')}>-</button>
      </div>
      <form>
        <div>
          <label className='label-branco'>Nome:</label>
          <input type="text" value={nome} onChange={(e) => setNome(e.target.value)} required />
        </div>
        <div>
          <label className='label-branco'>CPF:</label>
          <input  type="text" value={cpf} onChange={(e) => setCpf(formatarCPF(e.target.value))} maxLength="14"  pattern="[0-9]*" inputMode="numeric" required />
        </div>
        <div>
          <label className='label-branco'>Telefone:</label>
          <input type="text" value={telefone} onChange={(e) => setTelefone(formatarTelefone(e.target.value))} pattern="[0-9]*" inputMode="numeric" required />
        </div>
        <button className='bt-comprar' type="button" onClick={handleConcluirCompra}>Concluir Compra</button>
        <button className='bt-comprar' type="button" onClick={closeModal}>Fechar</button>
        {errorEfi && <p className="modal-error">{errorEfi}</p>}
        {errorMessage && <p className="modal-error">{errorMessage}</p>}
      </form>
    </div>
  </div>
)}

{/* Modal de QR Code */}
{modalType === 'qrCode' && ( 
  <div id="qrCodeModal" className="modal-efi modal-open">
    <div className="modal-content">
      <button className="close-button" onClick={closeModal}>×</button>
      <div id="modal-body">
        <div id="charge-container">
          <div id="charge-info"></div>
          <div id="modal-pagamento-pix">
            {charge ? (
              <ModalPagamentoPix charge={charge} closeModal={closeModal} />
            ) : (
              <div className='aguardando'>Aguardando geração da cobrança...</div>
            )}

            {errorEfi && (
              <>
                <div className="modal-error">{errorEfi}</div>
                <button className="btn-erro" type="button" onClick={() => {
                  setErrorEfi(null);
                  handleComprar();
                }}>
                  Tentar novamente
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
)}





          {modalOpen_cpf_consulta && (
            <div className="modal-overlay_cpf_consulta">

              <div className='area-grafico-modal'>
              <div className='trevo-cpf'>
                <img className='img-modal-cpf' src='./img/chapeusorte.png'></img>
                </div>
                 </div>
             
              <div className="modal_cpf_consulta">

              
                
                <button className="close-btn_cpf_consulta" onClick={fecharModal_cpf_consulta}>
                  &times;
                </button>
                <div className='cpf-txt-consulta'>Consultar Bilhetes por CPF</div>

                <form onSubmit={handleSubmit_cpf_consulta}>
                <label className='label-branco'>CPF:</label>
                <input
    type="text"
    placeholder="Digite o CPF"
    value={cpf_cpf_consulta}
    onChange={(e) => setCpf_cpf_consulta(e.target.value)}
    inputMode="numeric" // Indica que o teclado numérico deve ser exibido
    pattern="[0-9]*" // Opcional: Restringe a entrada a apenas números
/>

                  <button type="submit" className="buscar-btn_cpf_consulta">
                    {loading_cpf_consulta ? 'Buscando...' : 'Buscar'}
                  </button>
                </form>

                {/* Exibe erro, se houver */}
                {error_cpf_consulta && <p className="error-msg_cpf_consulta">{error_cpf_consulta}</p>}

                {/* Exibe os dados dos bilhetes, se houver */}
                {dadosBilhetes_cpf_consulta && (
                  <div className="dados-bilhetes_cpf_consulta">
                    <div className='cpf-info-cliente'>Informações do Cliente</div>
                    <div className='cpf-nome-cliente'><strong>Nome:</strong> {dadosBilhetes_cpf_consulta.nome}</div>
                    <div className='cpf-cpf-cliente'><strong>CPF:</strong> {dadosBilhetes_cpf_consulta.cpf}</div>
                    <div className='cpf-tel-cliente'><strong>Telefone:</strong> {dadosBilhetes_cpf_consulta.telefone}</div>

                    <div className='txt-cpf-bilhetes'>Bilhetes</div>
                    <ul>
                      {dadosBilhetes_cpf_consulta.bilhetes.map((bilhete, index) => (
                        <li key={index}>
                          <div className='bilhete-numero-consulta'><strong>Bilhete:</strong> {bilhete.numerodobilhete}</div>
                          <p><strong>Transação:</strong> {bilhete.transacao}</p>
                          <div className='linha-premiada'>
                          {bilhete.ganhou && <div className='cpf-premiado'><strong>Premiado:</strong> {bilhete.ganhou}</div>}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          )}


<div className='nav-btns'>
        <div className='btn-stick-comprar' onClick={abrirModal_cpf_consulta}>
          <div className='icone-tickets'><IoTicketOutline />
          <div className='icone-tickets-txt'>Meus Bilhetes</div>
          </div>
        </div>
      </div>

      <div>
    

      

 
    {menuAberto && (
         <div className="menu-principal-fundo">
           <div className="menu-principal-conteudo">
             <div className="linha-menu-bt">
               <button className="btn-fechar-menu" onClick={fecharMenu}>
                 X
               </button>
             </div>
 
             <div className="linha-menu">
               <img
                 className="logotipo-telepix"
                 src="/img/logo_telepix.png"
                 alt="Logo Telepix"
               />
             </div>
 
             <div className="linha-menu">
               <div className="itens-menu" onClick={abrirGanhadores}>
                 Ganhadores
               </div>
               <div className="itens-menu" onClick={abrirSorteio}>Sorteios</div>
               <div className="itens-menu" onClick={abrirRegras}>Regras</div>
               <div className="itens-menu">Instagram</div>
               <div className="itens-menu" onClick={abrirContatos}>Contato</div>
             </div>
           </div>
         </div>
       )}
        <Ganhadores aberto={ganhadoresAberto} onFechar={fecharGanhadores} />
        <Sorteios aberto={sorteioAberto} onFechar={fecharSorteio} />
        <Regras aberto={regrasAberto} onFechar={fecharRegras} />
        <Contato aberto={contatosAberto} onFechar={fecharContatos} />
     </div>

        </div> 

        
       
            




      ) : (

        // CONCURSO OFF
        <div className='concurso-off'>
            <div className="circle-container">
                <div className="orbit orbit-outer">
                  <div className="ball"></div>
                  <div className="ball"></div>
                  <div className="ball"></div>
                </div>
                <div className="orbit orbit-inner">
                  
                </div>
                <div className="center-image">
                <img className='img-orb' src='img/off.png' alt='preparando...' />
                </div>
              </div>
          <div className='txt-off'>{message}</div>

          
          {modalOpen_cpf_consulta && (
            <div className="modal-overlay_cpf_consulta">

              <div className='area-grafico-modal'>
              <div className='trevo-cpf'>
                <img className='img-modal-cpf' src='./img/chapeusorte.png'></img>
                </div>
                 </div>
             
              <div className="modal_cpf_consulta">

              
                
                <button className="close-btn_cpf_consulta" onClick={fecharModal_cpf_consulta}>
                  &times;
                </button>
                <div className='cpf-txt-consulta'>Consultar Bilhetes por CPF</div>

                <form onSubmit={handleSubmit_cpf_consulta}>
                <label className='label-branco'>CPF:</label>
                <input
    type="text"
    placeholder="Digite o CPF"
    value={cpf_cpf_consulta}
    onChange={(e) => setCpf_cpf_consulta(e.target.value)}
    inputMode="numeric" // Indica que o teclado numérico deve ser exibido
    pattern="[0-9]*" // Opcional: Restringe a entrada a apenas números
/>

                  <button type="submit" className="buscar-btn_cpf_consulta">
                    {loading_cpf_consulta ? 'Buscando...' : 'Buscar'}
                  </button>
                </form>

                {/* Exibe erro, se houver */}
                {error_cpf_consulta && <p className="error-msg_cpf_consulta">{error_cpf_consulta}</p>}

                {/* Exibe os dados dos bilhetes, se houver */}
                {dadosBilhetes_cpf_consulta && (
                  <div className="dados-bilhetes_cpf_consulta">
                    <div className='cpf-info-cliente'>Informações do Cliente</div>
                    <div className='cpf-nome-cliente'><strong>Nome:</strong> {dadosBilhetes_cpf_consulta.nome}</div>
                    <div className='cpf-cpf-cliente'><strong>CPF:</strong> {dadosBilhetes_cpf_consulta.cpf}</div>
                    <div className='cpf-tel-cliente'><strong>Telefone:</strong> {dadosBilhetes_cpf_consulta.telefone}</div>

                    <div className='txt-cpf-bilhetes'>Bilhetes</div>
                    <ul>
                      {dadosBilhetes_cpf_consulta.bilhetes.map((bilhete, index) => (
                        <li key={index}>
                          <div className='bilhete-numero-consulta'><strong>Bilhete:</strong> {bilhete.numerodobilhete}</div>
                          <p><strong>Transação:</strong> {bilhete.transacao}</p>
                          <div className='linha-premiada'>
                          {bilhete.ganhou && <div className='cpf-premiado'><strong>Premiado:</strong> {bilhete.ganhou}</div>}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            </div>
          )}

<div className='nav-btns'>
        <div className='btn-stick-comprar' onClick={abrirModal_cpf_consulta}>
          <div className='icone-tickets'><IoTicketOutline />
          <div className='icone-tickets-txt'>Meus Bilhetes</div>
          </div>
        </div>
      </div>
          
        </div>



      )}

      

  


    </div>
  );

};

export default ConcursoPage;

