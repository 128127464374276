import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './client/Home';
import Dashboard from './admin/Dashboard';
import AberturaConcursos from './admin/Abrirconcurso';
import Login from './auth/Login';
import Register from './auth/Register';
import ProtectedRoute from './components/ProtectRoute'; // Importar a rota protegida
import Logout from './components/Logout';
import AddInfluencer from './admin/AddInfluencer';
import ViewInfluencers from './admin/ViewInfluencers';
import TabelaConcursos from './admin/VerConcurso'; // Componente que exibe concursos
import Pagamento from './client/Pagamento';




const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pagamento" element={<Pagamento />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/admin" element={<ProtectedRoute><Dashboard /></ProtectedRoute>}>
          <Route path="abrir-concurso" element={<ProtectedRoute><AberturaConcursos /></ProtectedRoute>} />
          <Route path="ver-concurso" element={<ProtectedRoute><TabelaConcursos /></ProtectedRoute>} />
          <Route path="ver-concurso/:page" element={<ProtectedRoute><TabelaConcursos /></ProtectedRoute>} /> {/* Rota de paginação */}
          <Route path="add-influencers" element={<ProtectedRoute><AddInfluencer /></ProtectedRoute>} />
          <Route path="influencers" element={<ProtectedRoute><ViewInfluencers /></ProtectedRoute>} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
