import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Remover o user_id do sessionStorage
    sessionStorage.removeItem('user_id');
    // Redirecionar para a página de login
    navigate('/login');
  }, [navigate]);

  return null; // Não renderiza nada enquanto faz o logout
};

export default Logout;
