import React, { useEffect, useState } from 'react';

const Bilhetes = () => {
  const [bilhetes, setBilhetes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Chama a API para buscar os bilhetes
    fetch('/bilhetes_premiados.php')
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro ao carregar bilhetes');
        }
        return response.json();
      })
      .then(data => {
        setBilhetes(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <p>Carregando bilhetes...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
  
      <div className="bilhete-linha">
        {bilhetes.length === 0 ? (
          <p>Nenhum bilhete disponível no momento.</p>
        ) : (
          bilhetes.map(bilhete => (
            <div key={bilhete.numerodobilhete} className='c-bilhete'>
              <div className='linha'>
                {/* Condiciona a exibição do nome do ganhador */}
                {bilhete.status === 'ativo' ? (
                  <>
                    <div className='c20 trace-lateral'>
                      <img className='barcode-img' src={bilhete.imagem || './img/barcode.png'} alt='barcode' />
                    </div>
                  </>
                ) : (
                  <>
                  
                  <div className='c20 trace-lateral'>
                    <div className='ganhador-status'>
                      <p className='txt-preto'>Ganhador:</p>
                      <p className='txt-preto'>{bilhete.nome}</p>
                    </div>
                    </div>
                    
                  </>
                )}
  
                <div className='c80'>
                  <div className='bilhete-txt-l1'># Bilhete:</div>
                  <div className='bilhete-txt-l2'>{bilhete.numerodobilhete}</div>
                  <div className='bilhete-txt-l3'>Valor/Prêmio</div>
                  <div className='bilhete-txt-l4'>R$ {bilhete.valor}</div>
  
                  <div className='bilhete-status'>
                    {bilhete.status === 'ativo' ? (
                      <>
                        <div className='sinalizador'></div>
                        <p className=''>Disponível</p>
                      </>
                    ) : (
                      <>
                        <div className='sinalizador-red'></div>
                        <p className='txt-branco'>Eba! Saiu!</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    
  );
}  
export default Bilhetes;
