import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const ProtectedRoute = ({ children }) => {
    const isAuthenticated = useAuth();

    if (isAuthenticated === null) {
        return <div>Loading...</div>;
    }

    console.log('Usuário autenticado:', isAuthenticated);
    return isAuthenticated ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
