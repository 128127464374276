import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AddInfluencer = () => {
  const [nome, setNome] = useState('');
  const [canal, setCanal] = useState('');
  const [seguidores, setSeguidores] = useState('');
  const [comissao, setComissao] = useState('');
  const [status, setStatus] = useState('ativo');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validação simples
    if (!nome || !canal || !seguidores || !comissao) {
      setMessage('Por favor, preencha todos os campos.');
      return;
    }

    try {
      const formData = new FormData();
      formData.append('nome', nome);
      formData.append('canal', canal);
      formData.append('seguidores', parseInt(seguidores));
      formData.append('comissao', parseFloat(comissao));
      formData.append('status', status);

      const response = await axios.post('http://localhost/core/add_influencer.php', formData);
      setMessage(response.data.message);

      // Redirecionar após sucesso
      if (response.data.success) {
        navigate('/admin/influencers');
      }
    } catch (error) {
      console.error(error);
      setMessage('Erro ao cadastrar influenciador.');
    }
  };

  return (
<div className="influencer-registration-container">
  <h2 className="influencer-registration-title">Cadastrar Influenciador</h2>
  <form className="influencer-registration-form" onSubmit={handleSubmit}>
    <input 
      type="text" 
      className="influencer-input" 
      value={nome} 
      onChange={(e) => setNome(e.target.value)} 
      placeholder="Nome" 
      required 
    />
    <input 
      type="text" 
      className="influencer-input" 
      value={canal} 
      onChange={(e) => setCanal(e.target.value)} 
      placeholder="Canal" 
      required 
    />
    <input 
      type="number" 
      className="influencer-input" 
      value={seguidores} 
      onChange={(e) => setSeguidores(e.target.value)} 
      placeholder="Seguidores" 
      required 
    />
    <input 
      type="number" 
      className="influencer-input" 
      value={comissao} 
      onChange={(e) => setComissao(e.target.value)} 
      placeholder="Comissão (%)" 
      required 
    />
    <select 
      className="influencer-select" 
      value={status} 
      onChange={(e) => setStatus(e.target.value)}
    >
      <option value="ativo">Ativo</option>
      <option value="inativo">Inativo</option>
    </select>
    <button type="submit" className="influencer-submit-button">Cadastrar</button>
  </form>
  {message && <p className="influencer-message">{message}</p>}
</div>

  );
};

export default AddInfluencer;
