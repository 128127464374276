import React, { useState, useEffect } from "react"; 
import { LuFileVideo } from "react-icons/lu";
import { MdOutlineDocumentScanner } from "react-icons/md";
import { LiaGolfBallSolid } from "react-icons/lia";




function Ganhadores({ aberto, onFechar }) {
    const [concursos, setConcursos] = useState([]);

    useEffect(() => {
        if (aberto) {
            fetch('/ganhadores_geral.php') // Atualize para o endpoint correto
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        setConcursos(data.concursos);
                    } else {
                        console.error(data.message);
                    }
                })
                .catch(error => {
                    console.error('Erro ao buscar os concursos:', error);
                });
        }
    }, [aberto]);

    // Função para formatar o prêmio principal em R$ com a máscara BRL
    const formatarPremio = (premio) => {
        return `R$ ${parseFloat(premio).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    };

    // Função para formatar a data
    const formatarData = (data) => {
        const date = new Date(data);
        const dia = String(date.getDate()).padStart(2, '0');
        const mes = String(date.getMonth() + 1).padStart(2, '0'); // Janeiro é 0!
        const ano = date.getFullYear();
        const horas = String(date.getHours()).padStart(2, '0');
        const minutos = String(date.getMinutes()).padStart(2, '0');
        return `${dia}/${mes}/${ano} - ${horas}:${minutos}`;
    };

    if (!aberto) return null;

    return (
        <div>
            <div className="menu-principal-fundo">
                <div className="menu-principal-conteudo">
                    <div className='linha-menu-bt'>
                        <button className="btn-fechar-menu" onClick={onFechar}>
                            X
                        </button>
                    </div>

                    <div className='linha-menu'>
                        Lista de Todas as edições
                    </div>

                    <div className="container-ganhadores-geral">
                        {concursos.map((concurso, index) => (
                            <div className="linha-ganhadores" key={index}>
                                <div className="coluna-ganhadores-img">
                                    <div className="linha-ganhadores-img">
                                        <img className="ganhadores-img" src={concurso.imagem} alt={concurso.nome} />
                                    </div>
                                    <div className="bilhete-ganhador">

                                        <div className="bilhete-linha">

                                        <div className="bilhete-numero-premiado">
                                            <div className="img-barcode">
                                            <img className='barcode-img' src='./img/barcode.png' alt='barcode' />
                                            </div>
                                            <div className="n-txt-bilhete">
                                            Bilhete Sorteado
                                            <div className="n-bilhete">
                                            {concurso.bilhete_sorteado_final}
                                            </div>
                                            </div>
                                            
                                            <div className=""></div>
                                        </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="coluna-ganhadores">
                                    <div className="ganhadores-concurso">{concurso.nome}</div>
                                    <div className="ganhadores-premio">
                                        Prêmio Principal:
                                        <div className="ganhadores-premio-total">{formatarPremio(concurso.premio_principal)}</div>
                                    </div>
                                    <div className="ganhadores-data">
                                        Data de Criação:
                                        <div className="ganhadores-data-total">{formatarData(concurso.data_criacao)}</div>
                                    </div>
                                    <div className="ganhadores-nome">
                                        Ganhador do Prêmio Principal:
                                        <div className="ganhadores-nome-total">
                                            {concurso.ganhador_principal}
                                        </div>
                                        <div className="reacoes-linha">
                                            <div className="icones-comprovantes">
                                            <LuFileVideo className="ico-reacao" />
                                            <span>Reação</span>
                                                </div>    
                                            <div className="icones-comprovantes">
                                            <LiaGolfBallSolid className="ico-reacao"  />
                                            <span>Sorteio</span>
                                                </div>    
                                            <div className="icones-comprovantes">
                                            <MdOutlineDocumentScanner className="ico-reacao" />
                                            <span>Comprovante</span>
                                                </div>    

                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Ganhadores;
