import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AberturaConcursos = () => {
  const [premioPrincipal, setPremioPrincipal] = useState('');
  const [premioExtra, setPremioExtra] = useState('');
  const [custoBilhete, setCustoBilhete] = useState('');
  const [bilhetesDisponiveis, setBilhetesDisponiveis] = useState('');
  const [bilhetesExtras, setBilhetesExtras] = useState('');
  const [nomeConcurso, setNomeConcurso] = useState('');
  const [imagemConcurso, setImagemConcurso] = useState(null);
  const [influencers, setInfluencers] = useState([]);
  const [resultado, setResultado] = useState(null);
  const [message, setMessage] = useState('');
  const [totalCustoInfluencers, setTotalCustoInfluencers] = useState(0); // Armazena o valor total dos influenciadores

  // Função para listar os influenciadores
  const listarInfluencers = () => {
    axios.post('/listar_influencers_ativos.php')
      .then(response => {
        const data = response.data;
        const adjustedData = data.map(influencer => ({
          ...influencer,
          percentual: parseFloat(influencer.comissao) || 0,
          valorPago: 0, // Inicializa o valorPago como 0
          status: 'ativo' // Assume que o influencer está ativo ao carregar
        }));
        setInfluencers(adjustedData);
      })
      .catch(error => {
        console.error('Erro ao listar influencers:', error);
      });
  };

  // Função para formatar valores em BRL
  const formatarMoeda = (valor) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(valor);
  };

const handleInputChange = (setter, isCurrency = false) => (e) => {
    let value = e.target.value;

    if (isCurrency) {
        value = value.replace(/[^0-9.,]/g, ''); 
        const decimalSeparator = value.includes(',') ? ',' : value.includes('.') ? '.' : null;

        if (decimalSeparator) {
            const [integerPart, decimalPart] = value.split(decimalSeparator);
            value = integerPart + decimalSeparator + decimalPart.replace(/[.,]/g, '');
        }
    }

    // Adicione a verificação se o valor é vazio
    if (value === '') {
        setter(''); // Define como vazio
    } else {
        setter(value); // Atualiza o valor
    }
};

  

  // Função para criar o concurso
  const criarConcurso = () => {
    const formData = new FormData();
    formData.append('nome_concurso', nomeConcurso);
    formData.append('bilhetes_extras', bilhetesExtras);
    formData.append('premio_principal', parseFloat(premioPrincipal.replace(',', '.'))); 
    formData.append('premio_extra', parseFloat(premioExtra.replace(',', '.')));
    formData.append('custo_bilhete', parseFloat(custoBilhete.replace(',', '.'))); // Verifique este valor no log
    formData.append('bilhetes_disponiveis', bilhetesDisponiveis);
    formData.append('influencers', totalCustoInfluencers.toFixed(2));
  
    console.log('Dados enviados para criação do concurso:', {
      nomeConcurso,
      bilhetesExtras,
      premioPrincipal: parseFloat(premioPrincipal.replace(',', '.')),
      premioExtra: parseFloat(premioExtra.replace(',', '.')),
      custoBilhete: parseFloat(custoBilhete.replace(',', '.')),
      bilhetesDisponiveis,
      influencers: totalCustoInfluencers.toFixed(2),
    });
  
    if (imagemConcurso) {
      formData.append('imagem_concurso', imagemConcurso);
    }
  
    axios.post('/criar_concurso.php', formData)
      .then(response => {
        setMessage(response.data.message);
        if (response.data.success) {
          resetFields();
        }
      })
      .catch(error => {
        console.error('Erro ao criar concurso:', error);
        setMessage('Erro ao criar concurso.');
      });
  };
  

  const resetFields = () => {
    setNomeConcurso('');
    setBilhetesExtras('');
    setPremioPrincipal('');
    setPremioExtra('');
    setCustoBilhete('');
    setBilhetesDisponiveis('');
    setImagemConcurso(null);
  };

  const handleImageUpload = (e) => {
    setImagemConcurso(e.target.files[0]);
  };

  // Função para simular os valores do concurso
  const simular = () => {
    const valorTotal = parseFloat(custoBilhete.replace(',', '.')) * parseFloat(bilhetesDisponiveis.replace(',', '.'));
    const custoTotalPremios = parseFloat(premioPrincipal.replace(',', '.')) + parseFloat(premioExtra.replace(',', '.'));
    

    // Calcular o custo total dos influenciadores
    const totalInfluencerCost = influencers.reduce((acc, influencer) => {
      if (influencer.status === 'ativo') {
        const valorPago = (influencer.percentual / 100) * valorTotal;
        influencer.valorPago = valorPago; // Atualiza o valor pago do influenciador
        return acc + valorPago;
      }
      return acc;
    }, 0);

    setTotalCustoInfluencers(totalInfluencerCost); // Armazena o custo total dos influenciadores

    const custoTotal = custoTotalPremios + totalInfluencerCost;
    const lucro = valorTotal - custoTotal;

    setResultado({ valorTotal, custoTotalPremios, totalInfluencerCost, custoTotal, lucro });
  };

  // Função para alternar o status do influenciador
  const toggleInfluencerStatus = (id) => {
    setInfluencers(influencers.map(influencer => {
      if (influencer.id === id) {
        influencer.status = influencer.status === 'ativo' ? 'inativo' : 'ativo';
      }
      return influencer;
    }));
  };

  useEffect(() => {
    listarInfluencers();
  }, []);

  return (
    <div className="abrir-concurso-container">
  <div className="abrir-concurso-row">
    {/* Card de Inputs */}
    <div className="abrir-concurso-card">
      <div className="abrir-concurso-card-header">Criar Concurso</div>
      <div className="abrir-concurso-card-body">
        <form>
          <div className="abrir-concurso-form-group">
            <label htmlFor="nomeConcurso">Nome do Concurso</label>
            <input
              type="text"
              className="abrir-concurso-input"
              id="nomeConcurso"
              value={nomeConcurso}
              onChange={e => setNomeConcurso(e.target.value)}
            />
          </div>
          <div className="abrir-concurso-form-group">
            <label htmlFor="bilhetesExtras">Bilhetes Extras</label>
            <input
              type="text"
              className="abrir-concurso-input"
              id="bilhetesExtras"
              value={bilhetesExtras}
              onChange={handleInputChange(setBilhetesExtras)}
            />
          </div>
          <div className="abrir-concurso-form-group">
            <label htmlFor="premioPrincipal">Prêmio Principal</label>
            <input
              type="text"
              className="abrir-concurso-input"
              id="premioPrincipal"
              value={premioPrincipal}
              onChange={handleInputChange(setPremioPrincipal, true)}
            />
          </div>
          <div className="abrir-concurso-form-group">
            <label htmlFor="premioExtra">Prêmio Extra</label>
            <input
              type="text"
              className="abrir-concurso-input"
              id="premioExtra"
              value={premioExtra}
              onChange={handleInputChange(setPremioExtra, true)}
            />
          </div>
          <div className="abrir-concurso-form-group">
            <label htmlFor="custoBilhete">Custo do Bilhete</label>
            <input
              type="text"
              className="abrir-concurso-input"
              id="custoBilhete"
              value={custoBilhete}
              onChange={handleInputChange(setCustoBilhete, true)}
            />
          </div>
          <div className="abrir-concurso-form-group">
            <label htmlFor="bilhetesDisponiveis">Bilhetes Disponíveis</label>
            <input
              type="text"
              className="abrir-concurso-input"
              id="bilhetesDisponiveis"
              value={bilhetesDisponiveis}
              onChange={handleInputChange(setBilhetesDisponiveis)}
            />
          </div>
          <div className="abrir-concurso-form-group">
            <label htmlFor="imagemConcurso">Imagem do Concurso</label>
            <input
              type="file"
              className="abrir-concurso-input-file"
              id="imagemConcurso"
              onChange={handleImageUpload}
            />
          </div>
          <div className="abrir-concurso-form-group">
            <label htmlFor="totalInfluencers">Custo Total com Influenciadores</label>
            <input
              type="text"
              className="abrir-concurso-input"
              id="totalInfluencers"
              value={formatarMoeda(totalCustoInfluencers)}
              readOnly
            />
          </div>
          <button
            type="button"
            className="abrir-concurso-btn abrir-concurso-btn-primary"
            onClick={criarConcurso}
          >
            Criar Concurso
          </button>
          <p>{message}</p>
        </form>
      </div>
    </div>

    {/* Card de Resultados */}
    <div className="abrir-concurso-card">
      <div className="abrir-concurso-card-header">Simular Concurso</div>
      <div className="abrir-concurso-card-body">
        <button
          className="abrir-concurso-btn abrir-concurso-btn-info"
          onClick={simular}
        >
          Simular
        </button>
        {resultado && (
          <div>
            <p>Valor Total: {formatarMoeda(resultado.valorTotal)}</p>
            <p>Custo Total de Prêmios: {formatarMoeda(resultado.custoTotalPremios)}</p>
            <p>Custo Total com Influenciadores: {formatarMoeda(resultado.totalInfluencerCost)}</p>
            <p>Custo Total: {formatarMoeda(resultado.custoTotal)}</p>
            <p>Lucro: {formatarMoeda(resultado.lucro)}</p>
          </div>
        )}
      </div>
    </div>

    {/* Card de Influencers */}
    <div className="abrir-concurso-card-influencers">
      <div className="abrir-concurso-card-header">Influenciadores</div>
      <div className="abrir-concurso-card-body">
        {influencers.map(influencer => (
          <div key={influencer.id}>
            <p>{influencer.nome}</p>
            <p>Comissão: {influencer.percentual}%</p>
            <p>Valor Pago: {formatarMoeda(influencer.valorPago)}</p>
            <button
              className="abrir-concurso-btn abrir-concurso-btn-warning"
              onClick={() => toggleInfluencerStatus(influencer.id)}
            >
              {influencer.status === 'ativo' ? 'Desativar' : 'Ativar'}
            </button>
            <hr />
          </div>
        ))}
      </div>
    </div>
  </div>
</div>

  );
  
};

export default AberturaConcursos;
